<template>
  <b-card
    class="task-step-item"
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      class="justify-content-start"
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="e => updateVisible(!visible, e)"
    >
      <slot name="header">
        <b-form-checkbox
          v-model="localValue"
          v-b-tooltip.hover.top="$t(localValue ? 'cards.onboarding.mark_as_undone' : 'cards.onboarding.mark_as_done')"
          :value="true"
          :disabled="isLoading"
        />
        <span class="ml-1 lead collapse-title">{{ title }}</span>

        <b-badge
          v-if="duration"
          class="ml-75"
          variant="light-secondary"
        >
          {{ duration }}
        </b-badge>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse, BFormCheckbox, VBTooltip, BBadge,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BBadge,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  emits: ['update'],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    duration: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: false, // this.$parent.hover,
      localValue: this.value,
      isLoading: false,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  watch: {
    localValue(newValue) {
      this.isLoading = true
      this.$emit('update', { value: newValue, callback: () => { this.isLoading = false } })
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true, e) {
      if (val && e && (e.target.className.search('custom-control') > -1)) {
        return
      }
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style>
.task-step-item .custom-control-label {
  cursor: pointer;
}
</style>
