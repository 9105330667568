import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@/libs/axios'
import settingModel from './settingModel'

const {
  fetchAll,
  fetchOne,
  updateAttribute,
} = useModelStoreModule(settingModel)

const updateMany = (ctx, { namespace, settings }) => new Promise((resolve, reject) => {
  axios
    .post(`dashboard/v1/${settingModel.name}`, {
      namespace,
      settings,
    })
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(response.data)
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => {
      if (error?.response?.status === 422) {
        // eslint-disable-next-line no-param-reassign
        error.response.data.errors = settingModel.resolveValidationErrors(error.response.data.errors)
      }
      return reject(error)
    })
})

export default {
  name: 'settings',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll: (ctx, queryParams) => fetchAll(ctx, queryParams),
    // fetchAll: (ctx, queryParams) => fetchOne(ctx, 'layout_settings'),
    fetchOne,
    updateOne: (ctx, { code, value }) => updateAttribute(ctx, { itemId: code, attribute: 'value', value }),
    updateMany,
  },
}
