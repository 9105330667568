<template>
  <b-card
    v-if="data"
    body-class="pb-50"
  >
    <h6 v-t="`cards.ordersCard.title`" />
    <h2 class="font-weight-bolder mb-1">
      {{ mkFormatter(data.total) }}
    </h2>
    <!-- chart -->
    <vue-apex-charts
      height="70"
      :options="chartOptionsComputed"
      :series="data.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { mkFormatter } from '../../../@core/utils/filter'
import {areaChartOptions, localeFont} from "@/views/pages/index/chartOptions";

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      defaultChartOptions: {
        fontFamily: localeFont,
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: false,
        },
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            top: -15,
            bottom: -15,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '20%',
            startingShape: 'rounded',
            colors: {
              backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
              backgroundBarRadius: 5,
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.info],

        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          style: {
            fontFamily: localeFont,
          },
          x: {
            // show: false,
          },
        },
        labels: [
          "SAT",
          "SUN",
          "MON",
          "TUE",
          "WED",
          "THU",
          "FRI"
        ],
      },
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(this.defaultChartOptions))
      options.labels = this.data.labels.map(label => this.$t(`week_days.${label}`))
      return options
    },
    chartDataComputed() {
      // this.chartData.forEach((series, i) => {
      //   const newSeries = series
      //   newSeries.name = this.seriesNames[i]
      //   return newSeries
      // })
      // return this.chartData
    },
  },
  methods: { mkFormatter },
}
</script>
