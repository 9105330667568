<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            10 - 22
          </h6>
          <h3 class="mb-0">
            {{ $t('cards.onboarding.minutes') }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ $t('cards.onboarding.title') }}
          </b-card-title>
          <b-card-text class="mb-0">
            {{ $t('cards.onboarding.subtitle') }}
          </b-card-text>
        </div>
        <div class="ml-auto p-2">
          <b-button
            variant="outline-primary"
            pill
            href="https://www.youtube.com/watch?v=VTbgB3x8g4c&t=59s"
            target="_blank"
          >
            <feather-icon
              icon="HelpCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('cards.onboarding.help_btn_label') }}</span>
          </b-button>
        </div>
      </div>

      <app-task-steps
        accordion
        type="border"
      >
        <!-- Brand -->
        <app-task-steps-item
          ref="brand_step"
          v-model="steps.brand"
          :title="$t('cards.onboarding.brand.title')"
          :duration="`2 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'brand'"
          @update="params => onTaskProgressUpdate('brand', params.value, () => {
            params.callback()
            steps.brand = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.brand.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.brand.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'generalSettings', params: {category: 'store', subcategory: 'general'} }"
              />
            </div>
            <img
              :src="stepsImages.brand"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>

        <!-- Product -->
        <app-task-steps-item
          ref="product_step"
          v-model="steps.product"
          :title="$t('cards.onboarding.product.title')"
          :duration="`2-5 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'product'"
          @update="params => onTaskProgressUpdate('product', params.value, () => {
            params.callback()
            steps.product = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.product.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.product.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'products.add' }"
              />
            </div>
            <img
              :src="stepsImages.product"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>

        <!-- Payment Method -->
        <app-task-steps-item
          ref="payment_step"
          v-model="steps.payment"
          :title="$t('cards.onboarding.payment.title')"
          :duration="`1-3 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'payment'"
          @update="params => onTaskProgressUpdate('payment', params.value, () => {
            params.callback()
            steps.payment = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.payment.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.payment.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'paymentSettings' }"
              />
            </div>
            <img
              :src="stepsImages.payment"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>

        <!-- Shipping Method -->
        <app-task-steps-item
          ref="shipping_step"
          v-model="steps.shipping"
          :title="$t('cards.onboarding.shipping.title')"
          :duration="`1-5 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'shipping'"
          @update="params => onTaskProgressUpdate('shipping', params.value, () => {
            params.callback()
            steps.shipping = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.shipping.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.shipping.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'shippingSettings' }"
              />
            </div>
            <img
              :src="stepsImages.shipping"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>

        <!-- Layout -->
        <app-task-steps-item
          ref="customize_step"
          v-model="steps.customize"
          :title="$t('cards.onboarding.layout.title')"
          :duration="`3 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'customize'"
          @update="params => onTaskProgressUpdate('customize', params.value, () => {
            params.callback()
            steps.customize = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.layout.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.layout.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'layoutSettings' }"
              />
            </div>
            <img
              :src="stepsImages.customize"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>

        <!-- Domain -->
        <app-task-steps-item
          ref="domain_step"
          v-model="steps.domain"
          :title="$t('cards.onboarding.domain.title')"
          :duration="`1-4 ${$t('cards.onboarding.minutes')}`"
          :is-visible="activeStep === 'domain'"
          @update="params => onTaskProgressUpdate('domain', params.value, () => {
            params.callback()
            steps.domain = params.value
          })"
        >
          <div class="d-flex justify-content-between">
            <div>
              <p>
                {{ $t('cards.onboarding.domain.description') }}
              </p>

              <b-button
                v-t="'cards.onboarding.domain.button_title'"
                variant="primary"
                class="mr-2"
                :to="{ name: 'generalSettings', params: {category: 'store', subcategory: 'domain'} }"
              />
            </div>
            <img
              :src="stepsImages.domain"
              alt=""
              height="200px"
            >
          </div>
        </app-task-steps-item>
      </app-task-steps>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, VBTooltip, BButton,
} from 'bootstrap-vue'
import AppTaskSteps from '@core/components/app-task-steps/AppTaskSteps.vue'
import AppTaskStepsItem from '@core/components/app-task-steps/AppTaskStepsItem.vue'
import store from '@/store'
import settingStoreModule from '@/views/models/settings/general-settings/settingStoreModule'
import { onMounted, onUnmounted } from 'vue'
import { toast } from '@core/utils/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BButton,
    AppTaskSteps,
    AppTaskStepsItem,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  emits: ['hide'],
  props: {
    progress: {
      type: Object,
      default: () => ({
        brand: false,
        product: false,
        payment: false,
        shipping: false,
        customize: false,
        domain: false,
      }),
    },
  },
  data() {
    return {
      steps: {
        brand: this.progress?.brand || false,
        product: this.progress?.product || false,
        payment: this.progress?.payment || false,
        shipping: this.progress?.shipping || false,
        customize: this.progress?.customize || false,
        domain: this.progress?.domain || false,
      },
      stepsImages: {
        brand: require('@/assets/images/onboarding/brand.svg'),
        product: require('@/assets/images/onboarding/product.svg'),
        payment: require('@/assets/images/onboarding/payment.svg'),
        shipping: require('@/assets/images/onboarding/shipping.svg'),
        customize: require('@/assets/images/onboarding/customize.svg'),
        // domain: require('@/assets/images/onboarding/domain.svg'),
        domain: require('@/assets/images/onboarding/domain-alt.svg'),
      },
      isStep5Visible: false,
      // activeStep: 1,
    }
  },
  computed: {
    activeStep() {
      if (!this.steps.brand) {
        return 'brand'
      }
      if (!this.steps.product) {
        return 'product'
      }
      if (!this.steps.payment) {
        return 'payment'
      }
      if (!this.steps.shipping) {
        return 'shipping'
      }
      if (!this.steps.customize) {
        return 'customize'
      }
      if (!this.steps.domain) {
        return 'domain'
      }
      return null
    },
  },
  watch: {
    activeStep(val) {
      if (val) {
        const stepRef = `${val}_step`
        if (Object.hasOwn(this.$refs, stepRef)) {
          this.$refs[stepRef].updateVisible(true, null)
        }
      } else {
        this.$emit('hide')
      }
    },
  },
  mounted() {
    // if (!this.steps.domain) {
    //   this.activeStep = 1
    // } else if (!this.steps.product) {
    //   this.activeStep = 2
    // } else if (!this.steps.payment) {
    //   this.activeStep = 3
    // } else if (!this.steps.shipping) {
    //   this.activeStep = 4
    // } else if (!this.steps.customize) {
    //   this.activeStep = 5
    // } else {
    //   this.activeStep = null
    // }
  },
  methods: {},
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'settings'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, settingStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, settingStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { t } = useI18nUtils()

    const onTaskProgressUpdate = (taskId, value, callback) => {
      const settings = {}
      settings[taskId] = value
      store.dispatch(`${STORE_MODULE_NAME}/updateMany`, { namespace: 'onboarding', settings })
        .then(response => {
          if (response.success) {
            callback(true)
          }
        }).catch(error => {
          toast(
            'warning',
            t('message.operation_failed'),
            error?.response?.data?.message || null,
            'AlertTriangleIcon',
            null,
          )
          callback(false, error)
        }).finally(() => {
          callback(null)
        })
    }

    return {
      onTaskProgressUpdate,
    }
  },
}
</script>
