<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <h4
        v-t="`cards.goalCard.title`"
        class="mb-0"
      />
      <!--      <b-card-text class="font-medium-5 mb-0">-->
      <!--        <feather-icon-->
      <!--          id="popover-edit"-->
      <!--          icon="EditIcon"-->
      <!--          size="21"-->
      <!--          class="text-muted cursor-pointer"-->
      <!--          @click="onShow"-->
      <!--        />-->
      <!--      </b-card-text>-->
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="245"
      class="my-2"
      :options="goalOverviewRadialBar"
      :series="[percentage]"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text
          v-t="`cards.goalCard.completed`"
          class="text-muted mb-0"
        />
        <h3 class="font-weight-bolder mb-0">
          <money
            :value="parseFloat(data.completed)"
            :decimals="0"
          />
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text
          v-t="`cards.goalCard.target`"
          class="text-muted mb-0"
        />
        <h3 class="font-weight-bolder mb-0">
          <money
            :value="parseFloat(data.target)"
            :decimals="0"
          />
        </h3>
      </b-col>
    </b-row>

    <!-- Our popover title and content render container -->
    <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
    <!-- We specify the same container as the trigger button, so that popover is close to button -->
    <b-popover
      ref="popover"
      target="popover-edit"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      container="my-container"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center">
          <span
            v-t="`cards.goalCard.edit_target`"
          />
        </div>
      </template>

      <div>
        <i-input
          v-model="targetValue"
          type="price"
          title="inputs.target"
          :errors="inputStatusComputed?[]:[true]"
          min="1"
          :currency="localeCurrency"
          required
        />

        <!-- button -->
        <b-button
          v-t="`Cancel`"
          size="sm"
          variant="danger"
          class="mr-1"
          @click="onClose"
        />
        <b-button
          v-t="`Save`"
          size="sm"
          variant="primary"
          @click="onOk"
        />
      </div>
    </b-popover>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, BPopover, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getLocaleCurrency } from '@core/utils/utils'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BPopover,

    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      targetValue: 0,
      popoverShow: false,

      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      localeCurrency: getLocaleCurrency(),
    }
  },
  computed: {
    inputStatusComputed() {
      return this.targetValue && this.targetValue >= 1
    },
    percentage() {
      return this.data.percentage
    },
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    onOk() {
      if (this.inputStatusComputed) {
        this.data.target = this.targetValue
        this.onClose()
      }
    },
    onShow() {
      this.targetValue = parseFloat(this.data.target)
    },
  },
}
</script>
