<script>
import { ContentLoader } from 'vue-content-loader'
import { computed } from 'vue'

export default {
  components: { ContentLoader },
  setup() {
    const isDarkSkin = computed(ctx => ctx.$store.state.appConfig.layout.skin === 'dark')
    const primaryColor = computed(() => (isDarkSkin.value ? '#283046' : '#eaeced'))
    const secondaryColor = computed(() => (isDarkSkin.value ? '#435178' : '#ffffff'))

    return {
      primaryColor,
      secondaryColor,
    }
  },
}
</script>

<template>
  <div>
    <!-- Large Screens -->
    <content-loader
      width="1200"
      height="900"
      view-box="0 0 1200 900"
      :speed="2"
      :primary-color="primaryColor"
      :secondary-color="secondaryColor"
      class="bv-d-sm-down-none"
    >
      <rect
        x="68"
        y="37"
        rx="3"
        ry="3"
        width="298"
        height="129"
      />
      <rect
        x="426"
        y="37"
        rx="3"
        ry="3"
        width="298"
        height="129"
      />
      <rect
        x="786"
        y="37"
        rx="3"
        ry="3"
        width="298"
        height="129"
      />
      <rect
        x="104"
        y="217"
        rx="3"
        ry="3"
        width="578"
        height="42"
      />
      <rect
        x="123"
        y="308"
        rx="3"
        ry="3"
        width="906"
        height="17"
      />
      <circle
        cx="951"
        cy="386"
        r="11"
      />
      <circle
        cx="986"
        cy="386"
        r="11"
      />
      <rect
        x="176"
        y="378"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="377"
        y="377"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="733"
        y="377"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="127"
        y="418"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <circle
        cx="952"
        cy="447"
        r="11"
      />
      <circle
        cx="987"
        cy="447"
        r="11"
      />
      <rect
        x="177"
        y="439"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="378"
        y="438"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="734"
        y="438"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="128"
        y="479"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <circle
        cx="953"
        cy="505"
        r="11"
      />
      <circle
        cx="988"
        cy="505"
        r="11"
      />
      <rect
        x="178"
        y="497"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="379"
        y="496"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="735"
        y="496"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="129"
        y="537"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <circle
        cx="954"
        cy="566"
        r="11"
      />
      <circle
        cx="989"
        cy="566"
        r="11"
      />
      <rect
        x="179"
        y="558"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="380"
        y="557"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="736"
        y="557"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="130"
        y="598"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <circle
        cx="953"
        cy="626"
        r="11"
      />
      <circle
        cx="988"
        cy="626"
        r="11"
      />
      <rect
        x="178"
        y="618"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="379"
        y="617"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="735"
        y="617"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="129"
        y="658"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <circle
        cx="954"
        cy="687"
        r="11"
      />
      <circle
        cx="989"
        cy="687"
        r="11"
      />
      <rect
        x="179"
        y="679"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="380"
        y="678"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="736"
        y="678"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="127"
        y="716"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <rect
        x="123"
        y="312"
        rx="3"
        ry="3"
        width="2"
        height="465"
      />
      <rect
        x="1027"
        y="312"
        rx="3"
        ry="3"
        width="2"
        height="465"
      />
      <circle
        cx="954"
        cy="747"
        r="11"
      />
      <circle
        cx="989"
        cy="747"
        r="11"
      />
      <rect
        x="179"
        y="739"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="380"
        y="738"
        rx="3"
        ry="3"
        width="299"
        height="15"
      />
      <rect
        x="736"
        y="738"
        rx="3"
        ry="3"
        width="141"
        height="15"
      />
      <rect
        x="127"
        y="776"
        rx="3"
        ry="3"
        width="897"
        height="2"
      />
      <rect
        x="124"
        y="343"
        rx="3"
        ry="3"
        width="906"
        height="17"
      />
      <rect
        x="125"
        y="320"
        rx="3"
        ry="3"
        width="68"
        height="33"
      />
      <rect
        x="294"
        y="317"
        rx="3"
        ry="3"
        width="149"
        height="33"
      />
      <rect
        x="616"
        y="318"
        rx="3"
        ry="3"
        width="137"
        height="33"
      />
      <rect
        x="854"
        y="319"
        rx="3"
        ry="3"
        width="72"
        height="33"
      />
      <rect
        x="1005"
        y="317"
        rx="3"
        ry="3"
        width="22"
        height="33"
      />
      <circle
        cx="743"
        cy="237"
        r="20"
      />
      <rect
        x="739"
        y="217"
        rx="0"
        ry="0"
        width="67"
        height="40"
      />
      <circle
        cx="802"
        cy="237"
        r="20"
      />
      <circle
        cx="890"
        cy="238"
        r="20"
      />
      <rect
        x="890"
        y="218"
        rx="0"
        ry="0"
        width="140"
        height="40"
      />
      <circle
        cx="1032"
        cy="238"
        r="20"
      />
    </content-loader>
    <!-- Small Screens -->
    <content-loader
      width="1200"
      height="1500"
      view-box="0 0 900 1200"
      :speed="2"
      :primary-color="primaryColor"
      :secondary-color="secondaryColor"
      class="d-xl-none d-lg-none d-md-none"
    >
      <rect
        x="68"
        y="37"
        rx="3"
        ry="3"
        width="1016"
        height="250"
      />

      <rect
        x="68"
        y="387"
        rx="3"
        ry="3"
        width="1016"
        height="250"
      />

      <rect
        x="68"
        y="737"
        rx="3"
        ry="3"
        width="1016"
        height="250"
      />

      <rect
        x="68"
        y="1087"
        rx="3"
        ry="3"
        width="1016"
        height="250"
      />
    </content-loader>
  </div>
</template>
