<template>
  <b-card
    no-body
    :class="`border-${data.status_class}`"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5
        v-t="`cards.current_plan.title`"
        class="mb-0"
      />
      <b-badge
        v-t="`subscription_statuses.${data.status}`"
        :variant="`light-${data.status_class}`"
      />
      <small class=" w-100">{{ data.plan ? data.plan.name : '-' }}</small>
    </b-card-header>

    <b-card-body>
      <ul
        v-if="data.periods && data.periods.length"
        class="list-unstyled my-1"
      >
        <li class="my-25">
          <span class="align-middle">
            {{ remainingDays > 0 ? remainingDays : 0 }}
            <span v-t="`cards.current_plan.days_left`" />
          </span>
        </li>
        <li>
          <span class="align-middle font-small-2">
            <span v-t="data.status == 'EXPD' ? `cards.current_plan.expired_on` : `cards.current_plan.valid_till`" />:
            {{ formatDate(endAt) }}
          </span>
        </li>
      </ul>
      <ul
        v-else
        class="list-unstyled my-1"
      >
        <li class="my-25">
          <span class="align-middle">
            <span v-t="`cards.current_plan.no_plan_note`" />
          </span>
        </li>
      </ul>
      <b-button
        v-t="'cards.current_plan.details'"
        variant="outline-primary"
        block
        :to="{name: 'subscription'}"
      />
      <b-button
        v-t="data.periods.length ? `cards.current_plan.renew_plan` : `cards.current_plan.select_plan`"
        variant="primary"
        block
        :to="{ name: 'plans' }"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        status_class: 'secondary',
        status: 'INACTIVE',
        name: null,
        days_left: 0,
        expiry_date: null,
      }),
    },
  },
  computed: {
    remainingDays() {
      return this.data.periods.reduce((carry, period) => period.remaining + carry, 0)
    },
    endAt() {
      return this.data.periods.reduce((carry, period) => period.end_at, 0)
    },
  },
  methods: { formatDate },
}
</script>

<style>

</style>
