<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ $t('cards.recentOrdersCard.title') }}</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item :to="{name: 'orders.list'}">
          {{ $t('cards.recentOrdersCard.all_order') }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body v-if="data.length">
      <div
        v-for="order in data"
        :key="order.id"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="`light-${order.class}`"
            >
              <feather-icon
                size="18"
                :icon="order.icon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <b-link :to="{name: 'orders.view', params: {id: order.id}}">
              <h6 class="transaction-title cursor-pointer">
                {{ order.items > 1 ? $t('cards.recentOrdersCard.x_products', {x: order.items}) : $t('cards.recentOrdersCard.one_product') }}
              </h6>
              <small>{{ order.date }}</small>
            </b-link>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
        >
          <money
            :value="order.total / 100"
            :short="false"
          />
        </div>
      </div>
    </b-card-body>
    <b-card-body
      v-else
      class="mt-2"
    >
      <b-card-text
        v-t="`cards.orderStatesCard.placeholder`"
        class="font-small text-secondary"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BLink,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BLink,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
