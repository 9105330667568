<template>
  <b-card>
    <b-card-title
      v-t="`cards.orderStatesCard.title`"
      class="mb-1"
    />
    <b-card-sub-title
      v-t="`cards.orderStatesCard.subtitle`"
      class="mb-2"
    />
    <vue-apex-charts
      v-if="Object.keys(data.series).length"
      type="donut"
      height="350"
      class="order-states-donut-chart"
      :options="chartOptions(data.series, data.total)"
      :series="formatSeriesData(data.series)"
    />
    <b-card-body v-else>
      <b-card-text
        v-t="`cards.orderStatesCard.placeholder`"
        class="font-small text-secondary"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { orderStatusOptions } from '@core/utils/constants'
import { getLocale } from '@core/utils/utils'

export default {
  components: {
    BCardBody,
    BCardText,
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    localeFont() {
      return getLocale() === 'ar' ? 'Almarai' : 'Montserrat'
    },
  },
  methods: {
    formatSeriesData(data) {
      return orderStatusOptions.filter(status => status.value in data).map(status => data[status.value].count)
      // return orderStatusOptions.slice(1).map(status => (typeof (data[status.value]) !== 'undefined' ? data[status.value].count : 0))
    },
    chartOptions(series, total) {
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: this.localeFont,
        },
        colors: orderStatusOptions.filter(status => status.value in series).map(status => status.color),
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: this.localeFont,
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: this.localeFont,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '0.5rem',
                  label: this.$t('Orders'),
                  formatter() {
                    return total
                  },
                },
              },
            },
          },
        },
        labels: orderStatusOptions.filter(status => status.value in series).map(status => status.label),
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
  },
}
</script>

<style>
.order-states-donut-chart .apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label {
  font-size: 1rem;
}
</style>
