import { $themeColors } from '@themeConfig'
import { getLocale } from '@core/utils/utils'

const $trackBgColor = '#EBEBEB'

export const localeFont = getLocale() === 'ar' ? 'Almarai' : 'Montserrat'
export const areaChartOptions = {
  fontFamily: localeFont,
  grid: {
    borderColor: $trackBgColor,
    strokeDashArray: 5,
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2.5,
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.9,
      opacityFrom: 0.5,
      opacityTo: 0.2,
      stops: [0, 80, 100],
    },
  },
  theme: {
    monochrome: {
      enabled: true,
      color: $themeColors.primary,
      shadeTo: 'light',
      shadeIntensity: 0.65,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    // type: 'numeric',
    lines: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: { show: false },
  },
  yaxis: [
    {
      y: 0,
      offsetX: 0,
      offsetY: 0,
      padding: {
        left: 0,
        right: 0,
      },
    },
  ],
  title: {
    style: {
      fontFamily: localeFont,
    },
  },
  tooltip: {
    style: {
      fontFamily: localeFont,
    },
    // x: { show: false },
    // y: { show: false },
  },
}
